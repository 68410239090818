import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { LayoutPage } from "layouts/LayoutPage";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { PageHeader } from "components/PageHeader";
import { Text } from "components/Text";

import styles from "./PageFAQ.module.scss";

type Props = {
  title: string;
  faqs: { question: string; answer: string }[];
};

export function PageFAQDetails({ title, faqs }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<number[]>([]);

  const onClick = (index: number) => {
    if (open.includes(index)) {
      setOpen(open.filter((i) => i !== index));
    } else {
      setOpen([...open, index]);
    }
  };

  return (
    <LayoutPage bgColor="var(--background-primary)" paddingTop={120}>
      <div className={styles.content}>
        <PageHeader title={title} />

        <section className={styles.linksContainer}>
          {faqs.map((faq, index) => {
            const isOpen = open.includes(index);

            return (
              <Card key={index} noPadding className={styles.faqBtn}>
                <Button
                  text={faq.question}
                  icon={isOpen ? "chevronUp" : "chevronDown"}
                  intent="clear"
                  onClick={() => onClick(index)}
                  fill
                  textLeft
                />

                <>
                  {isOpen && (
                    <div className={styles.answer}>
                      <Text as="p" style="body">
                        {faq.answer}
                      </Text>
                    </div>
                  )}
                </>
              </Card>
            );
          })}
        </section>

        <Link to="/support/chat">
          <Card>
            <Button
              text={t("pages.faq.chatWithUs")}
              icon="chevronRight"
              intent="clear"
              fill
              textLeft
            />
          </Card>
        </Link>
      </div>
    </LayoutPage>
  );
}
