import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { Pill } from "components/Pill";
import { Text } from "components/Text";

import { formatCurrencyWithNumberFormat } from "lib/helpers";
import { InvoiceStatus } from "lib/types";

import styles from "./InvoiceHeroDetails.module.scss";

type Props = {
  invoiceId: string;
  displayId: string;
  amount: string;
  currency: string;
  dueDate: string;
  status: InvoiceStatus;
  dateOfPayment?: string;
  language: string;
};

export function InvoiceHeroDetails({
  invoiceId,
  displayId,
  amount,
  currency,
  dueDate,
  status,
  dateOfPayment,
  language,
}: Props) {
  const { t } = useTranslation();

  let pillIntent: "success" | "clear" | "danger" = "clear";

  if (status === "paid") {
    pillIntent = "success";
  } else if (status === "debtCollection") {
    pillIntent = "danger";
  }

  return (
    <Card>
      <div className={styles.header}>
        <Text as="p" style="body" strong>
          {t("features.invoice.details")}
        </Text>
      </div>
      <div className={styles.details}>
        <div>
          <Text as="p" style="body">
            {t("features.invoice.invoice")}
          </Text>
          <Text as="p" style="body">
            {formatCurrencyWithNumberFormat(amount, language, currency, true)}
          </Text>
        </div>

        <div>
          <Text as="p" style="body">
            {t("features.invoice.invoiceNumber")}
          </Text>
          <Text as="p" style="body">
            {displayId}
          </Text>
        </div>

        <div>
          <Text as="p" style="body">
            {t("features.invoice.expirationDate")}
          </Text>
          <Text as="p" style="body">
            {dueDate}
          </Text>
        </div>

        {dateOfPayment && (
          <div>
            <Text as="p" style="body">
              {t("features.invoice.dateOfPayment")}
            </Text>
            <Text as="p" style="body">
              {dateOfPayment}
            </Text>
          </div>
        )}

        <div>
          <Text as="p" style="body">
            {t("features.invoice.status")}
          </Text>
          <Pill text={status} intent={pillIntent} />
        </div>
      </div>

      <>
        {(status === "created" ||
          status === "sent" ||
          status === "reminder") && (
          <div className={styles.btnContainer}>
            <Link to={`/${invoiceId}/payment`}>
              <Button
                text={t("features.invoice.pay", { amount: amount, currency })}
                intent="primary"
                icon="arrowRight"
                onClick={() => undefined}
              />
            </Link>
          </div>
        )}

        {status === "debtCollection" && (
          <div className={styles.btnContainer}>
            <Link to="https://intrum.se" target="_blank">
              <Button
                text={t("features.invoice.payIntrum")}
                intent="primary"
                icon="arrowUpRight"
                onClick={() => undefined}
              />
            </Link>
          </div>
        )}
      </>
    </Card>
  );
}
