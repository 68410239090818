import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";
import { Text } from "components/Text";

import styles from "./SurveyStars.module.scss";

// TODO: Send API request with feedback
export function SurveyStars() {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [hoveredStar, setHoveredStar] = useState<number>(-1);

  const handleStarHover = (index: number) => {
    setHoveredStar(index);
  };

  const handleStarLeave = () => {
    setHoveredStar(-1);
  };

  const handleStarClick = () => {
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <section className={styles.surveyStars}>
        <Text as="h5" style="h2">
          {t("components.surveyStars.thankYou")}
        </Text>
      </section>
    );
  }

  return (
    <section className={styles.surveyStars}>
      <Text as="h5" style="h2">
        {t("components.surveyStars.title")}
      </Text>
      <div className={styles.starsContainer}>
        {[...Array(5)].map((_, index) => (
          <Icon
            key={index}
            name={hoveredStar >= index ? "starFilled" : "star"}
            onMouseDown={handleStarClick}
            onMouseOver={() => handleStarHover(index)}
            onMouseLeave={handleStarLeave}
          />
        ))}
      </div>
    </section>
  );
}
