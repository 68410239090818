import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { prepareHeaders } from "lib/prepareHeaders";
import { Invoice } from "lib/types";

interface Response<T> {
  data: T;
  totalItems: number;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_INVOICE_URL,
  credentials: "include",
  prepareHeaders,
});

const idtoDisplayId = (id: string) => {
  return `INV-${id.slice(-6).toUpperCase()}`;
};

export const invoiceServiceSlice = createApi({
  reducerPath: "invoiceServiceApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getInvoice: builder.query<Invoice, string>({
      query: (id) => `/invoices/${id}`,
      transformResponse: (res: Invoice) => {
        res.displayId = idtoDisplayId(res.id);
        return res;
      },
    }),
    getAllInvoices: builder.query<
      {
        data: Invoice[];
        fetched: number;
      },
      string | number
    >({
      query: (page) => `/invoices?page=${page}&limit=20`,
      merge: (currentCache, newItems) => {
        if (!currentCache) {
          currentCache = {
            data: [],
            fetched: 0,
          };
        }

        if (newItems.data.length > 0) {
          currentCache.data.push(...newItems.data);
          currentCache.fetched = newItems.data.length;
        } else {
          currentCache.fetched = 0;
        }
      },
      serializeQueryArgs: ({ endpointName }: { endpointName: string }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (res: Response<Invoice[]>) => {
        return {
          data: res.data.map((invoice) => {
            invoice.displayId = idtoDisplayId(invoice.id);
            return invoice;
          }),
          fetched: res.data.length,
        };
      },
    }),
    resendEmail: builder.mutation<boolean, string>({
      query: (id) => ({
        url: `/invoices/${id}/resend-email`,
        method: "POST",
      }),
      transformResponse: (res: boolean) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetInvoiceQuery,
  useGetAllInvoicesQuery,
  useResendEmailMutation,
} = invoiceServiceSlice;
