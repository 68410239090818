import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  InvoiceActivity,
  InvoiceHeroDetails,
  InvoiceItems,
} from "features/invoice";
import { LayoutPage } from "layouts/LayoutPage";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { CardAd } from "components/CardAd";
import { Icon } from "components/Icon";
import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";
import { SurveyStars } from "components/SurveyStars";
import { Text } from "components/Text";

import { copyToClipboard } from "lib/helpers";
import { useHead } from "lib/hooks/useHead";
import {
  useGetInvoiceQuery,
  useResendEmailMutation,
} from "lib/slices/invoiceServiceSlice";
import { IRootState } from "lib/store";

import styles from "./PageInvoiceDetails.module.scss";

export function PageInvoiceDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const { data: invoice, isFetching } = useGetInvoiceQuery(params.id as string);
  const [resendEmail] = useResendEmailMutation();
  useHead(t("pages.invoiceDetails.title"));

  const language = useSelector((state: IRootState) => state.language.language);

  const [sentEmailStatus, setSentEmailStatus] = useState("initial");

  const handleEmailPDF = async (invoiceId: string) => {
    if (sentEmailStatus === "initial") {
      setSentEmailStatus("loading");

      try {
        const response = await resendEmail(invoiceId).unwrap();
        await new Promise((res) => setTimeout(res, 100));
        if (response === true) {
          setSentEmailStatus("success");
        }
      } catch (error) {
        setSentEmailStatus("initial");
        console.error("Failed to resend email", error);
      }
    }
  };

  let intentValue: "tertiary" | "success" | "disabled" = "disabled";

  if (sentEmailStatus === "initial") {
    intentValue = "tertiary";
  } else if (sentEmailStatus === "loading") {
    intentValue = "disabled";
  } else if (sentEmailStatus === "success") {
    intentValue = "success";
  }

  if (isFetching) {
    return (
      <LayoutPage bgColor="--background-primary">
        <div className={styles.content}>
          <div className={styles.invoiceDetails}>
            <SkeletonGroup
              wrapperStyles={{
                borderRadius: "16px",
              }}
              options={[
                {
                  width: "100%",
                  height: "300px",
                  borderRadius: "16px",
                  marginBottom: "16px",
                },
                {
                  width: "100%",
                  height: "150px",
                  borderRadius: "16px",
                  marginBottom: "16px",
                },
                {
                  width: "100%",
                  height: "450px",
                  borderRadius: "16px",
                  marginBottom: "16px",
                },
              ]}
            />
          </div>
        </div>
      </LayoutPage>
    );
  } else if (!invoice) {
    navigate("/");
    return null;
  } else {
    return (
      <LayoutPage bgColor="--background-primary">
        <div className={styles.content}>
          <div className={styles.invoiceDetails}>
            <InvoiceHeroDetails
              invoiceId={invoice.id}
              displayId={invoice.displayId}
              amount={invoice.totalAmount}
              currency={invoice.currency}
              dueDate={invoice.dueDate}
              status={invoice.status}
              language={language}
            />

            <Card>
              <div className={styles.invoiceNumber}>
                <Text as="p" style="body">
                  {t("features.invoice.paymentReference")}
                </Text>
                <div>
                  <Text as="p" style="body" color="--content-subtle">
                    {invoice.clientOCRReference}
                  </Text>
                  <Button
                    intent="clear"
                    onClick={() => copyToClipboard(invoice.clientOCRReference)}
                  >
                    <div className={styles.copyBtn}>
                      <Icon name="copy" size={14} />
                    </div>
                  </Button>
                </div>
              </div>
              <div className={styles.invoiceNumber}>
                <Text as="p" style="body">
                  {t("features.invoice.bankName")}
                </Text>
                <div>
                  <Text as="p" style="body" color="--content-subtle">
                    {/* TODO MOCK REMOVE */}
                    {invoice.bankName ?? "Nordea"}
                  </Text>
                </div>
              </div>
              <div className={styles.invoiceNumber}>
                <Text as="p" style="body">
                  {t("features.invoice.iban")}
                </Text>
                <div>
                  <Text as="p" style="body" color="--content-subtle">
                    {/* TODO MOCK REMOVE */}
                    {invoice.IBAN ?? "SE1234567890"}
                  </Text>
                  <Button
                    intent="clear"
                    onClick={() =>
                      copyToClipboard(invoice.IBAN ?? "SE1234567890")
                    }
                  >
                    <div className={styles.copyBtn}>
                      <Icon name="copy" size={14} />
                    </div>
                  </Button>
                </div>
              </div>
            </Card>

            <InvoiceItems
              invoiceItems={invoice.InvoiceItems}
              vatAmount={invoice.VATAmount}
              currency={invoice.currency}
              language={language}
            />

            {invoice.Histories.length > 0 && (
              <InvoiceActivity
                timeline={invoice.Histories}
                language={language}
              />
            )}

            <div className={styles.ctaBtns}>
              <a href={invoice.pdfURL} download={`invoice-${invoice.id}`}>
                <Button
                  intent="tertiary"
                  text={t("features.invoice.download")}
                  icon="download"
                  textLeft
                />
              </a>
              <Button
                intent={intentValue}
                disabled={
                  intentValue === "disabled" || intentValue === "success"
                }
                text={t("features.invoice.emailPDF")}
                icon={sentEmailStatus !== "success" ? "mail" : "tick"}
                textLeft
                onClick={() => handleEmailPDF(invoice.id)}
              />
            </div>
          </div>

          <div className={styles.extras}>
            <CardAd size="21/9" href="#" />
            <SurveyStars />
          </div>
        </div>
      </LayoutPage>
    );
  }
}
