import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { InvoiceHero } from "features/invoice";
import { LayoutPage } from "layouts/LayoutPage";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { CardAd } from "components/CardAd";
import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";

import { useHead } from "lib/hooks/useHead";
import { useGetInvoiceQuery } from "lib/slices/invoiceServiceSlice";

import styles from "./PageHome.module.scss";

export function PageHome() {
  const { t } = useTranslation();
  const params = useParams();
  const { data: invoice, isFetching } = useGetInvoiceQuery(params.id as string);
  useHead(t("pages.home.title"));

  return (
    <LayoutPage bgColor="--background-primary">
      <div className={styles.content}>
        {isFetching && !invoice && (
          <Card noPadding>
            <SkeletonGroup
              options={[
                {
                  width: "100%",
                  height: "300px",
                  borderRadius: "16px",
                  marginBottom: "16px",
                },
              ]}
            />
          </Card>
        )}
        {invoice && (
          <InvoiceHero
            invoiceTitle={invoice.description}
            invoiceId={invoice.id}
            displayId={invoice.displayId}
            amount={invoice.totalAmount}
            currency={invoice.currency}
            dueDate={invoice.dueDate}
            status={invoice.status}
            reminderFee={invoice.reminderFee}
          />
        )}

        {/* <InfoMessage /> */}

        <Link to="/all-invoices">
          <Card>
            <Button
              text={t("pages.home.allInvoices")}
              icon="chevronRight"
              intent="clear"
              fill
              textLeft
            />
          </Card>
        </Link>
        <CardAd href="#" size="3/2" />
        <Link to="/faq">
          <Card>
            <Button
              text={t("pages.home.faq")}
              icon="chevronRight"
              intent="clear"
              fill
              textLeft
            />
          </Card>
        </Link>
      </div>
    </LayoutPage>
  );
}
